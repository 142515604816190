export default {
  name: 'empty-data',
  props: {
    text: {
      type: String,
      default: null
    },
  },
  data() {
    return {}
  },

  created() {
  },
  mounted() {},
  methods: {
  },
  destroyed() {
  }
}
