export default {
  name: 'static-content',
  props: {
    item: {
      type: Object,
      default: () => {
      }
    }
  }
}
